import React,{useState, useEffect} from 'react'
import axiox from 'axios'
import HomeItem from '../componets/HomeItem'
import SearchHiddenBlock from '../componets/SearchHiddenBlock'


function MainSection() {
  const [allData, setAllData]=useState([])
  const [postLink, setPostLink] = useState("https://err57nk.pythonanywhere.com/api/posts")  
  const [postData, setPostData] = useState([])
  const [success, setSuccess] = useState(true)

  // const [btnShow, setBtnShow] = useState('')  
  

  useEffect(()=>{
    axiox.get(postLink)
    .then(res =>{
      setAllData(res.data)
      setPostData(res.data.results)     
      console.log(res.data)
    })
    .catch(err => {
      console.log(err)
      setSuccess(false)      
    })
  },[postLink])

  function getPosts(newPage){
    if(newPage!= null){
      setPostLink(newPage)
      console.log(newPage)
    }
  }

if(success){
  return (
    <div className="blog-layout__mainSec">
    <SearchHiddenBlock/>
    <div className="nmt-30"></div>
    
    <div className="nmp-box">
      <h2>Recent Blog ({allData['count']})</h2>
      <div className="nrc-box nmt-20">
      
        {
          postData.map((item) =>(
            <HomeItem key={item.id} result = {item}/>
          ))
        }
      </div>
    </div>
    
      <div className="paginator nmt-40 npt-10">  
        <button className="nbtn-small nmr-10" onClick={()=>getPosts(allData['previous'])}>&#x3c; Previous</button>   
        <button className="nbtn-small nml-10" onClick={()=>getPosts(allData['next'])}>Next &#x3e;</button>  
      </div>
    
    </div>
    )
}else{
  return(
<h2>Somthing went Wrong Refresh Page or Try Again </h2>

  )
}



}

export default MainSection
import React,{useState, useEffect} from 'react'
import axiox from 'axios'

import SidePop from '../componets/SidePop'
import SideRes from '../componets/SideRes'
import SideCat from '../componets/SideCat'
import SearchBar from '../componets/SearchBar'
import UserBox from '../componets/UserBox'
import SideSocialConnect from '../componets/SideSocialConnect'

function SideSec() {
  const [cat, setCat]=useState([])
  const [pop, setPop]=useState([])
  const [res, setRes]=useState([])
  const [sideLink, setSideLink] = useState("https://err57nk.pythonanywhere.com/api/sidebar/")  
 

  useEffect(()=>{
    axiox.get(sideLink)
    .then(res =>{
      console.log("SideData",res.data)
      setPop(res.data.pop_post)
      setRes(res.data.res_post)
      setCat(res.data.cat)
    })
    .catch(err => {
      console.log(err)      
    })
  },[sideLink])

return (

<div className="blog-layout__sideSec">

  <SearchBar/>
  
  <SideSocialConnect/>
  <UserBox/>
  
  
  <div className="post-blog" id="postSec">
    <h4>Post Blog</h4>
    <a href="https://err57nk.pythonanywhere.com/Post/new/" className="nbtn nbtn-primary">Post Blog</a>
  </div>

  <SideCat cat={cat}/>
  <SidePop pop={pop} />
  <SideRes res={res} />


</div>

)
}

export default SideSec
import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import Header from './sections/Header'
import Footer from './sections/Footer'
import Home from './pages/Home'
import PostDetail from './pages/PostDetail'
import Author from './pages/Author'
import Result from './pages/Result'



function App() {



const mainLinks={
'Facebook':'https://www.facebook.com/TechNick57',
'Youtube':'https://www.youtube.com/c/TechNick57',
'Linkedin':'https://www.linkedin.com/in/nitesh-kr',
'Github':'https://www.github.com/error57nk',
'Instagram':'https://www.instagram.com/am_nitesh/',
}


return (
<>
  <Router>         
      <Header soc={mainLinks} />

      
      
      <Switch>

      <Route path="/" exact>  
        <div className="ncontainer-fluid">
          <div className="blog-layout">   
              <Home />  
          </div>
        </div>       
      </Route>
        
      
      <Route path="/post/:slug" exact>
        <PostDetail/>
      </Route>

      <Route path="/author/:username" exact>
        <Author/>
      </Route>

      <Route path="/category/:srcKey" exact>
      <div className="ncontainer-fluid">
          <div className="blog-layout">   
            <Result nurl="category/?cat="/>
        </div>
        </div>
      </Route>

      <Route path="/result/:srcKey" exact>
        <div className="ncontainer-fluid">
          <div className="blog-layout">   
            <Result nurl="result/?search="/>  
          </div>
        </div>       
      </Route>


      <Route path="/" render={()=>(<h4 className="nmt-50 npt-50 nmb-50 npb-50 n-center">Page Not Found 404</h4>)}/>

      </Switch>
        
        


     

    <Footer soc={mainLinks} />
  </Router>
</>
);
}

export default App;
import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'

function SearchHiddenBlock() {
  const [queryKey,setQueryKey] = useState("")
  const history = useHistory();
  function submitQuery(e){
    e.preventDefault()
    if(queryKey.trim() !== ""){
      history.push("/result/" + queryKey)
    }   
  }
  return (
  <div class="src-box nhd" id="raw-src">
    <form onSubmit={submitQuery}>
      <div class="form-group">
        <input type="text" class="form-control" placeholder="Search" onChange={(e)=>setQueryKey(e.target.value)} />
        <button type="submit" class="src-icon-btn">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </form>
  </div>
  )
}

export default SearchHiddenBlock

import React from 'react'

function SideSocialConnect() {
  return (
    <div className="connect-box">
    <h4>Connect</h4>
    <ul className="npl-0 social-v">
      <li>
        <a href="https://www.linkedin.com/in/nitesh-kr" rel="noreferrer" target="_blank"><i
            className="fab fa-linkedin"></i></a>
      </li>
      <li>
        <a href="https://github.com/Error57nk" rel="noreferrer" target="_blank"><i className="fab fa-github"></i></a>
      </li>
      <li>
        <a href="https://www.youtube.com/c/TechNick57" rel="noreferrer" target="_blank"><i
            className="fab fa-youtube"></i></a>
      </li>
      <li>
        <a href="https://wa.me/919679310464" rel="noreferrer" target="_blank"><i className="fab fa-facebook"></i></a>
      </li>
      <li>
        <a href="https://wa.me/919679310464" rel="noreferrer" target="_blank"><i className="fab fa-instagram"></i></a>
      </li>
    </ul>
  </div>


  )
}

export default SideSocialConnect

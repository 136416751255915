import React from 'react'
import Logo from '../images/logo.png'
import IconFacebook from '../images/icons/facebook.png'
import IconGithub from '../images/icons/github.png'
import IconLinkedin from '../images/icons/linkedin.png'
import IconYoutube from '../images/icons/youtube.png'
import IconInstagram from '../images/icons/instagram.png'
// import LogoFacebook from '../images/icons/facebook.png'
import {Link} from 'react-router-dom'
function Header({soc}) {
  return (
<header className="head">
  <Link to="/" className="head__logo">
    <img src={Logo} alt="Front Root" />
    <h2><span>Tech </span>Nick</h2>
  </Link>
  <div className="head__links">
    <ul>
      <li>
        <a href={soc.Facebook} rel="noreferrer" target="_blank"><img
            src={IconLinkedin} alt="linkedin" /></a>
      </li>
      <li>
        <a href={soc.Github} rel="noreferrer" target="_blank"><img
            src={IconGithub} alt=" github" /></a>
      </li>

      <li>
        <a href={soc.Facebook} rel="noreferrer" target="_blank"><img
            src={IconFacebook} alt="facebook" /></a>
      </li>

      <li>
        <a href={soc.Youtube} rel="noreferrer" target="_blank"><img
            src={IconYoutube} alt="youtube" /></a>
      </li>

      <li>
        <a href={soc.Instagram} rel="noreferrer" target="_blank"><img
            src={IconInstagram} alt="instagram" /></a>
      </li>
      <li className="nusernk">
        <button id="userdot" className="nusernk__btn">
          <i className="fas fa-ellipsis-v"></i>
        </button>
        <ul className="nusernk__list">
          
          <li>
            <a href="/profile/"><i className="far fa-user"></i>&nbsp;Profile</a>
          </li>
          <li>
            <a href="/Post/new/"><i className="fas fa-plus"></i>&nbsp;Create Post</a>
          </li>

          <li>
            <a href="/logout/"><i className="fas fa-sign-out-alt"></i>&nbsp;Logout</a>
          </li>
          
        </ul>
      </li>
    </ul>
  </div>
</header>
  )
}

export default Header

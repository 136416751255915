import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {useParams} from 'react-router'

import SideSec from '../sections/SideSec'
import ResultItem from '../componets/ResultItem'

function Author() {
  let { username } = useParams();
  const postLink = "https://err57nk.pythonanywhere.com/api/author/" + username
  const [posts, setPost] = useState([])
  const [userData, setuserData] = useState({})
  const [totalPost, setTotalPost] = useState(0)
  const [sucess, setSucess] = useState(true)

  useEffect(()=>{
    axios.get(postLink)
    .then((res)=>{
      setPost(res.data['post'])
      setuserData(res.data['userdata'])
      setTotalPost(res.data.tpost)
      
    })
    .catch((err)=>{
      console.log("Error",err)
      setSucess(false)      
    })
  },[postLink])

  console.log("Post",posts)
  console.log("Profile",userData)
  console.log("TotalPost",totalPost)
  if(sucess && posts){
  return (
    <>
    <div className="header-banner">
  <img src={userData['banner']} alt={userData.userName} className="img-fluid" />
  <div className="header-banner__info">
    <div className="header-banner__info_img">
      <img src={userData.profilepic} className="img-fluid" alt={userData.userName} />
    </div>
    <div className="header-banner__info_des">
      <h4>{userData.userName} (<small></small>)</h4>
      <p>{userData.link1}</p>

      <ul className="social-v">
        <li className=" ">
          <a href={userData.link1} className="primary-color2" rel="noreferrer" target="_blank"><i
              className={userData.link1Icon}></i></a>
        </li>
        <li className=" ">
          <a href={userData.link2} className="primary-color2" rel="noreferrer" target="_blank"><i
              className={userData.link2Icon}></i></a>
        </li>
        <li className=" ">
          <a href={userData.link3} className="primary-color2" rel="noreferrer" target="_blank"><i
              className={userData.link3Icon}></i></a>
        </li>
        <li className=" ">
          <a href={userData.link4} className="primary-color2" rel="noreferrer" target="_blank"><i
              className={userData.link4Icon}></i></a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div className="ncontainer-fluid">
  <div className="blog-layout">
    <div className="blog-layout__mainSec">

      <div className="profile">
        <div className="">
          <h2 className="">About : {userData.userName}</h2>
          <p>{userData.bio}</p>
        </div>
      </div>

      <div className="profile-blog nmt-30">
        <h4 className="">Total Post ({totalPost}) Showing {posts.length}</h4>
        <hr/>
        <div className="res-box nmt-20">

        {
          posts.map((item)=>(<ResultItem post={item}/>))
        }  
      

        </div>
      </div>
    
    <a href={"https://err57nk.pythonanywhere.com/author/" + userData.userName} className="nbtn-small" rel="noreferrer" target="_blank"><small>View All Post in Main Site</small></a>
    </div>


   <SideSec/>


  </div>
</div>
      
    </>
  )
      }
      else{
        return(
          <div className="ncontainer-fluid">
              <div className="blog-layout">
                <div className="blog-layout__mainSec">
                  <h2 className="nmt-50">404 Page Not Found</h2>
                </div>
                <SideSec/>
              </div>
          </div>
        )
      }
}

export default Author

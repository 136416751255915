import React from 'react'
import {Link} from 'react-router-dom'
function ResultItem({post}) {

  function splitTag(){
    let newSrt = post['tag'].split(",");
    return newSrt.map((tag)=>(<span className="tagList">{tag}</span>))
  }

  return (
    <div className="res-box__item">
      <div className="res-box__item_img">
        <Link to={"/post/" + post.slug}><img src={post.thumb}
            className="img-fluid" alt={post.title} /></Link>
      </div>
  <div className="nbadge-2"><i className="fas fa-mouse"></i> {post.view}</div>
      <div className="res-box__item_info">
        <Link to={"/post/" + post.slug}>
          <h3 className="b-head">{post.title}</h3>
        </Link>
        <p>
          <span className="auth"><Link to={"/author/" + post.author}><i className="fa fa-user"></i> {post.author}</Link>
            &nbsp;|&nbsp;</span>
          <span><Link to={"/category/" + post.cat}><i className="fas fa-puzzle-piece"></i>&nbsp;
              {post.cat}</Link></span>&nbsp;|&nbsp;
  <span><i className="fas fa-puzzle-piece"></i>&nbsp; {post.date}</span>
        </p>
        <Link to={"/post/" + post.slug}>
          <p className="b-dec">{post['desc'].substring(0,115) + "..."}</p>
        </Link>
        <div className="">
          <p className="srcTag">{splitTag()}</p>
        </div>
      </div>
    </div>

  )
}

export default ResultItem

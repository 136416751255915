import React from 'react'
import {Link} from 'react-router-dom'
function SideCat({cat}) {
  return (
    <div className="category-box">
    <h4>Categories</h4>
    <ul className="npl-0">

    {
      cat.map((item)=>(
        <li className="cat-item" key={item.id}>
          <Link to={"/category/" + item.cat}>
            <img src={item.catImg} className="img-fluid" alt={item.cat} />
            <p>{item.cat}</p>
          </Link>
        </li>
      ))
    }


    </ul>
  </div>

  )
}

export default SideCat

import React from 'react'
import Logo from '../images/logo.png'
function Footer({soc}) {
  return (
    <>
      <section className="pf nbt-6">
  <div className="pffoot">
    <div className="pffoot__img">
      <img src={Logo} alt="Tech Nick" />
    </div>
    <div className="pffoot__info">
      <p>
        For Website Design contact us: <br />
        <a href="http://frontroot.co/contact.php" rel="noreferrer" target="_blank"
          >@frontroot.co</a
        >
      </p>
      <span></span>
    </div>
    <div className="pffoot__legal">
      <p className="pf_links">
        <span
          ><a href={soc.Linkedin} rel="noreferrer" target="_blank"
            ><i className="fab fa-linkedin"></i> nitesh-kr</a
          ></span
        >
        <span
          ><a href={soc.Github} rel="noreferrer" target="_blank"
            ><i className="fab fa-github"></i> error57nk</a
          ></span
        >
        <span
          ><a href={soc.Youtube} rel="noreferrer" target="_blank"
            ><i className="fab fa-youtube"></i> technick</a
          ></span
        >
      </p>
      <p className="nmb-0">&#169; 2021 Technick</p>
    </div>
  </div>
</section>
<p className="ft-lwr">
  Design By: <a href={soc.Linkedin}>frontroot.co</a>
</p>

<div className="page-scroller">
  <a href="#"><i className="fas fa-angle-up"></i></a>
</div>

    </>
  )
}

export default Footer

import React from 'react'
import {Link} from 'react-router-dom'
function SidePop({title, pop}) {
  return (
    <div className="popular-box">
    <h4>{title || "Popular Posts"}</h4>
    <ul className="npl-0 popular-box__ul">
      
      {
        pop.map((item)=>(
          <li className="popu-item" key={item.id}>
            <Link to={"/post/" + item.slug}>
              <img src={item.thumb} className="img-fluid" alt={item.title} height="60" width="70" />
              <div className="popu-item__info">
              <p className="side-head">{item.title}</p>
                <div className="popu-des">
                  <span className="pop-item__user">
                    <i className="far fa-user"></i> {item.author} |
                  </span>
                  <span className="pop-item__cat">
                    <i className="fas fa-puzzle-piece"></i> {item.cat}
                  </span>
                </div>
              </div>
            </Link>
          </li>
        ))
      }       

    </ul>
  </div>


   
  )
}

export default SidePop

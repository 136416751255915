import React from 'react'
import Moment from 'moment'
import {Link} from 'react-router-dom'

function HomeItem({result}) {

  function splitTag(tagstr){
    let newSrt = tagstr.split(",");
    return newSrt.map((skill,index)=>(<span key={index}>{skill}</span>))
  }

  return (
    <div className="nrc-items">
    <Link to={'/post/'+result.slug} className="nrc-items__img nf-center"><img src={result.thumb}
        className="img-fluid" alt={result.author} /></Link>
    <div className="nbadge-2"><i className="fas fa-mouse"></i> {result.view}</div>
    <div className="nrc-items__info">
      <Link to={"/result/?cat=" + result.cat} className="nnowrap"><i className="fas fa-puzzle-piece"></i> {result.cat}
      </Link>
      &nbsp;|&nbsp;
      <Link to={"/author/" + result.author} className="nnowrap"><i className="far fa-user"></i> {result.author}</Link>
      &nbsp;|&nbsp;
      <span className="nnowrap"><i className="far fa-clock"></i> {Moment(result.date).format('D MMM YY')} </span>
    </div>
    <div className="nrc-items__des">
      <Link to={'/post/'+result.slug} className="nrc-items__des_head">
        <h4>{result.title}</h4>
        <p className="nrc-items__des_lead">{result['desc'].substring(0,115) + "..."}</p>
      </Link>
  
      <div className="nrc-items__des_tag">
        {splitTag(result.tag)}        
        <span>err57nk</span>
  
  
      </div>
    </div>
  </div>
  )
}

export default HomeItem

import React, {useState, useEffect} from 'react'
import axiox from 'axios'
import parse from 'html-react-parser'
import {useParams} from 'react-router'
import {Link} from 'react-router-dom'


import SidePop from '../componets/SidePop'
import SideRes from '../componets/SideRes'
import SideCat from '../componets/SideCat'
import SearchBar from '../componets/SearchBar'
// import TagSpilt from '../componets/TagSpilt'



function PostDetail() {
let { slug } = useParams();
const postLink = "https://err57nk.pythonanywhere.com/api/post/" + slug


const [post, setPost]= useState({})
const [userdata,setuserData]= useState({})
const [pop, setpop]= useState([])
const [res, setres]= useState([])
const [cat, setcat]= useState([])
const [tag, settag]= useState([])
const [success, setSuccess]= useState(true)

useEffect(()=>{
axiox.get(postLink)
.then(res =>{
setPost(res.data['post'])
setuserData(res.data['userdata'])
setpop(res.data['postByUser'])
setres(res.data['postByCat'])
setcat(res.data['cat'])
settag(()=>res.data.post['tag'].split(','))


console.log("All Data=>",res.data)

})
.catch(err => {
console.log(err)
setSuccess(false)
})
},[postLink])

if(success){
  return (

    <>
      <div class="header-banner">
        <img src={post.banner} alt="Banner" class="img-fluid" />
      </div>
    
    <div class="ncontainer-fluid">
          <div class="blog-layout">
            <div class="blog-layout__mainSec">
                <div class="pv-hsec">
                  <div class="pv-hsec__thumb">
                    <img src={post.thumb} alt={post.title} class="img-fluid" />
                  </div>
                  <div class="pv-hsec__info">
                    <div class="">
                      <h2>{post.title}</h2>
                      <p>
                        <span class="auth"><Link to={"/author/" + post.author}><i class="fa fa-user"></i> {post.author}</Link>
                          &nbsp;|&nbsp;</span>
    
                        <span><i class="fas fa-puzzle-piece"></i>&nbsp;
                          {post.date} &nbsp;|&nbsp;</span>
                        <span><Link to={"/category/" + post.cat}><i class="fas fa-puzzle-piece"></i>&nbsp;
                        {post.cat}</Link>&nbsp;|&nbsp;</span>
                        <span> <i class="fas fa-mouse"></i>&nbsp;{post.view} </span>
                      </p>
    
                      <div class="pv-social">
                        <ul class="sul">
                          <li class="nfont-15 npl-10 npr-10">
                            <a href={userdata.link1} class="primary-color2" rel="noreferrer" target="_blank"><i
                                class={userdata.link1Icon}></i></a>
                          </li>
                          <li class="nfont-15 npl-10 npr-10">
                            <a href={userdata.link2} class="primary-color2" rel="noreferrer" target="_blank"><i
                                class={userdata.link2Icon}></i></a>
                          </li>
                          <li class="nfont-15 npl-10 npr-10">
                            <a href={userdata.link3} class="primary-color2" rel="noreferrer" target="_blank"><i
                                class={userdata.link3Icon}></i></a>
                          </li>
                          <li class="nfont-15 npl-10 npr-10">
                            <a href={userdata.link4} class="primary-color2" rel="noreferrer" target="_blank"><i
                                class={userdata.link4Icon}></i></a>
                          </li>
                          
                        </ul>
                      </div>
                    </div>
                  </div>
    
                  <div class="pv-hsec__bread">
                    <p>
                      <Link to="/"><i class="fas fa-home"></i> Home &nbsp;</Link>
                      <span><i class="fas fa-chevron-right"></i>&nbsp; {slug}</span>
                    </p>
                  </div>
                </div>
    
    
                <div class="bcon">
                  <p class="bcon__con">
                    { parse(String(post['content']))}
                  </p>
    
                  <hr class="nhr-1" />
                  <div class="bcon__comments">
                    <h4>Comment:</h4>
                    <p>Currently Unavailable :-(</p>
                  </div>
                  <hr class="nhr-1" />
    
        
                  <div class="bcon__tag">
                    <p class="srcTag">#Tags &nbsp;                  
                          {tag.map((t)=>(<span key={t.index} className="tagList">{t}</span>))}                
                    </p>
                  </div>
        
                </div>
            </div>
    
    
            <div class="blog-layout__sideSec">
              <SearchBar/>
              <SideCat  cat={cat}/>
              <SidePop title="Post By User" pop={pop} />
              <SideRes title={post.cat} res={res}/>
            </div>
    
    
          </div>
    </div>
    </>
    )
}else{
  return (<h4 className="nmt-50 npt-50 nmb-50 npb-50 n-center">Page Not Found 404
  <br/>
  <br/>
  <Link to='/'>Go to Home </Link>
  
  </h4>)
}


}

export default PostDetail
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {useParams} from 'react-router'

import SideSec from '../sections/SideSec'
import ResultItem from '../componets/ResultItem'
import SearchHiddenBlock from '../componets/SearchHiddenBlock'


function Result({nurl}) {

  let { srcKey } = useParams();
  const postLink = "https://err57nk.pythonanywhere.com/api/"+ nurl + srcKey

  // const [postLink, setPostLink] = useState(postLinki)
  const [posts, setPosts] = useState({})
  const [dataPosts, setDataPost] = useState({})
  const [success, setSuccess] = useState(true)
  const [next, setNext] = useState("")
  const [prev, setPrev] = useState("")
  
  

  useEffect(()=>{
    axios.get(postLink)
    .then((res)=>{
      setPosts(res.data['results'])
      setDataPost(res.data)       
    })
    .catch((err)=>{
      console.log(err)
      setSuccess(false)      
    })
  },[postLink])

 

if(success && posts.length>0){  return (
    <>
    <div className="blog-layout__mainSec">
      <SearchHiddenBlock/>
      <h2 class="res-head">Result For "{srcKey}" ({posts.length})</h2>
      <div class="res-box">
        {
          posts.map((item)=>(<ResultItem key={item.index} post={item}/>))
        }
      </div>
      <div className="paginator nmt-40 npt-10">
        {next}
        {prev}
      </div>
    </div>
      <SideSec/>
    </>
  )
}else{
  return(
    <>
     <div className="blog-layout__mainSec">
    <h2 class="nmt-40">Search Result (0)</h2>
<p><b>Your search</b> - <b>{srcKey}</b> - did not match any documents.</p>
<p><b>Suggestions:</b></p>
<ul class="bcon__ul_dot">
  <li>Make sure that all words are spelled correctly.</li>
  <li>Try different keywords.</li>
  <li>Try more general keywords.</li>
  <li>Try fewer keywords.</li>
</ul>
</div>
<SideSec/>
</>
  )
}
}

export default Result

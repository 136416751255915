import React from 'react'
import {Link} from 'react-router-dom'
function SideRes({title, res}) {
  return (
    <div className="recent-box">
    <h4>{title || "Recent Posts"}</h4>
    <ul className="npl-0 recent-box__ul">

    {
      res.map((item)=>(
        <li className="rec-item" key={item.id}>
          <Link to={"/post/" + item.slug}>
            <span className="rec-item__cat"><i className="fas fa-puzzle-piece"></i> {item.cat}</span>
            <p className="side-head"> {item.title}</p>

            <div className="rec-item__info">
              <span><i className="far fa-user"></i> {item.author} |</span>
              <span className="rec-item__date"> <i className="far fa-clock"></i> {item.date}</span>
            </div>
          </Link>
      </li>
      ))
    }


    </ul>
  </div>
  )
}

export default SideRes

import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
function SearchBar() {
  const [queryKey,setQueryKey] = useState("")
  const history = useHistory();
  function submitQuery(e){
    e.preventDefault()
    if(queryKey.trim() !== ""){
      history.push("/result/" + queryKey)
    }   
  }
  return (
    <div className="src-box" id="srcBox">
    <h4>Search</h4>
    <form onSubmit={submitQuery}>
      <div className="form-group">
        <input type="text" className="form-control" placeholder="Search" onChange={(e)=>setQueryKey(e.target.value)} />
        <button type="submit" className="src-icon-btn">
          <i className="fas fa-search"></i>
        </button>
      </div>
    </form>
  </div>
  )
}

export default SearchBar

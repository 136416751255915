import React from 'react'

import MainSection from '../sections/MainSection'
import SideSec from '../sections/SideSec'

function Home({soc}) {
  return (
      <>
        <MainSection/>
        <SideSec soc={soc}/>
      </>
  )
}

export default Home

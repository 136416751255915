import React from 'react'

function UserBox() {
  return (
    <div class="admin-sec">
  <h4><i class="far fa-user"></i> User</h4>
  <a href="https://err57nk.pythonanywhere.com/login/" rel="noreferrer" target="_blank" class="npl-20"
    ><i class="far fa-user"></i> Login</a
  >
  <br />
  <small class="npl-20"
    ><a href="https://err57nk.pythonanywhere.com/register/" rel="noreferrer" target="_blank">or, Create Account</a></small
  >
</div>
  )
}

export default UserBox
